const devServer = {
  // serviceUrl: 'wss://meet.chatmosphere.cc/xmpp-websocket',
  serviceUrl: '//meet.chatmosphere.cc/http-bind',
  hosts: {
    domain: "meet.chatmosphere.cc",
    muc: 'conference.meet.chatmosphere.cc',
    // anonymousdomain: ''
  },
  bosh: '//meet.chatmosphere.cc/http-bind',

  clientNode: 'http://jitsi.org/jitsimeet'
}

const demoServer = {
  // serviceUrl: 'wss://jitsi.chatmosphere.cc/xmpp-websocket',
  serviceUrl: '//jitsi.chatmosphere.cc/http-bind',
  hosts: {
    domain: "jitsi.chatmosphere.cc",
    muc: 'conference.jitsi.chatmosphere.cc',
    // anonymousdomain: ''
  },
  bosh: '//jitsi.chatmosphere.cc/http-bind',

  clientNode: 'http://jitsi.org/jitsimeet'
}



export const connectionOptions = process.env.REACT_APP_PUBLIC_SERVER === "public" ? {...demoServer} : {...devServer}